//assets
import {
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialYoutube,
  IconSocialTwitter,
  IconSocialLinkedin,
} from '@loadsmart/icons';
import LoadsmartLogo from '@/public/images/logos/loadsmart_logotype.svg';
import AppStoreIcon from '@/public/images/icons/icon-applestore.svg';
import GooglePlayIcon from '@/public/images/icons/icon-googleplay.svg';

//styles
import {
  ContainerWrapper,
  MainContent,
  LogoWrapper,
  LogoContent,
  NavWrapper,
  NavSegments,
  NavSegmentsGroup,
  SegmentLabel,
  SegmentSubItems,
  NavIcons,
  NavSocials,
  NavApps,
  LegalWrapper,
  CopyRightWrapper,
  Spacer,
} from './Footer.styles';

const menuItems = [
  {
    label: 'Shippers',
    cta: { url: '/shipper/' },
    submenu: [
      {
        label: 'Shipper solutions',
        description: `Instantly price, book and ship with guaranteed capacity for FTL, LTL, Drayage and Multimodal. Navigate any market condition.`,
        url: '/shipper/',
        external: false,
      },
      {
        label: 'Managed transportation',
        description: `Consulting services, end-to-end freight planning,
                    procurement and execution services.`,
        url: '/shipper/managed-transportation/',
        external: false,
      },
      {
        label: 'ShipperGuide TMS',
        description: `Plan, procure and execute freight with the next generation Transportation Management System.`,
        url: '/shipper/shipperguide/',
        external: false,
      },
      {
        label: 'FreightIntel AI',
        description: ``,
        url: 'https://lp.loadsmart.com/freight-intel-ai?utm_source=loadsmart&utm_medium=cta&utm_campaign=fi-ai-midmarket-fintech-q3-24&utm_content=navigation',
        external: true,
      },
      {
        label: 'Freight brokerage',
        description: `Instantly price, book and ship with guaranteed capacity for
                    FTL, LTL, Drayage and Multimodal.`,
        url: '/shipper/',
        external: false,
      },
      {
        label: 'Private fleet optimizer',
        description: ` Match unused capacity with available spot and contract
                    demand.`,
        url: 'https://lp.loadsmart.com/loadsmart-fleet-optimization',
        external: true,
      },
      {
        label: 'Flatbed messenger',
        description: `Get access to premium capacity from top performing carriers.`,
        url: '/shipper/flatbed-messenger/',
        external: false,
      },
      {
        label: 'Expedited shipping',
        description: ``,
        url: '/shipper/expedited-shipping/',
        external: false,
      },
    ],
  },
  {
    label: 'Carriers',
    cta: { url: '/carrier/' },
    submenu: [
      {
        label: 'Carrier solutions',
        description: `Carriers can instantly bid, book, and move freight with top
                    shipper freight.`,
        url: '/carrier/',
        external: false,
      },
      {
        label: 'Carrier loadboard',
        description: `Find the right loads to grow your business.`,
        url: `${process.env.CARRIER_URL}/#/`,
        external: true,
      },
      {
        label: 'Carrier TMS',
        description: `Optimize dispatch. Reduce overhead costs. Scale fleets.`,
        url: '/carrier/tms/',
        external: false,
      },
      {
        label: 'Factoring',
        description: `No more emails, phone calls or whiteboards, create invoices
                    easily.`,
        url: 'https://lp.loadsmart.com/factoring-sign-up',
        external: true,
      },
    ],
  },
  {
    label: 'Docks & Yards',
    cta: { url: '/warehouse/' },
    submenu: [
      {
        label: 'Warehouse solutions',
        description: ` Dock appointment scheduling software for warehouses and carriers.`,
        url: '/warehouse/',
        external: false,
      },
      {
        label: 'Dock scheduling',
        description: ` Dock appointment scheduling software for warehouses and carriers.`,
        url: 'https://opendock.com/',
        external: true,
      },
      {
        label: 'Yard and Asset visibility',
        description: `Asset tracking software and hardware for greater yard visibility.`,
        url: 'https://navtrac.com/',
        external: true,
      },
    ],
  },
  {
    label: 'About',
    cta: { url: '/about/' },
    submenu: [
      {
        label: 'Who we are',
        url: '/about/',
        external: false,
      },
      {
        label: 'Careers',
        url: '/careers/',
        external: false,
      },
      {
        label: 'Partnerships',
        url: 'https://lp.loadsmart.com/loadsmart-partner-portal-1',
        external: true,
      },
      {
        label: 'Resources',
        url: 'https://content.loadsmart.com/',
        external: true,
      },
      {
        label: 'Blog',
        url: 'https://blog.loadsmart.com/',
        external: true,
      },
      {
        label: 'Contact',
        url: '/contact/',
        external: false,
      },
      {
        label: 'Community',
        url: 'https://community.loadsmart.com/hc/en-us',
        external: true,
      },
      {
        label: 'Loadsmart API',
        url: 'https://developer.loadsmart.com/docs/',
        external: true,
      },
    ],
  },
];

const legalItems = [
  {
    label: 'User Agreement',
    cta: { url: '/user-agreement', external: false },
  },
  {
    label: 'Subscription and Professional Services Agreement',
    cta: {
      url: '/subscription-and-professional-services-agreement',
      external: false,
    },
  },
  {
    label: 'Managed Transportation Master Services Agreement',
    cta: {
      url: '/managed-transportation-master-services-agreement',
      external: false,
    },
  },
  {
    label: 'Privacy Policy',
    cta: { url: '/privacy-policy', external: false },
  },
  {
    label: 'Security',
    cta: { url: 'https://lp.loadsmart.com/security', external: true },
  },
];

const socialItems = [
  {
    service: 'LinkedIn',
    user: '@loadsmart',
    url: 'https://www.linkedin.com/company/loadsmart',
    icon: <IconSocialLinkedin width="56" height="56" />,
  },
  {
    service: 'Instagram',
    user: '@loadsmart',
    url: 'https://www.instagram.com/loadsmart',
    icon: <IconSocialInstagram width="56" height="56" />,
  },
  {
    service: 'Twitter',
    user: '@LoadSmartUS',
    url: 'https://twitter.com/LoadSmartUS/',
    icon: <IconSocialTwitter width="56" height="56" />,
  },
  {
    service: 'YouTube',
    user: '@Loadsmart',
    url: 'https://www.youtube.com/channel/UCN-pkKcoMargmyL0Br3-H5w',
    icon: <IconSocialYoutube width="56" height="56" />,
  },
  {
    service: 'Facebook',
    user: '@loadsmartUS',
    url: 'https://www.facebook.com/loadsmartUS',
    icon: <IconSocialFacebook width="56" height="56" />,
  },
];

export function Footer({ theme }) {
  const currentYear = new Date().getFullYear();

  return (
    <ContainerWrapper theme={theme}>
      <MainContent id="main-menu-wrapper">
        <LogoWrapper href="/" aria-label="Home">
          <LogoContent>
            <LoadsmartLogo width="263.01" height="44.23" />
          </LogoContent>
        </LogoWrapper>
        <NavWrapper>
          <NavSegments>
            {menuItems.map((item, index) => {
              const keyNav = `navmenu-${item.label.trim()}-${index}`;
              return (
                <NavSegmentsGroup key={keyNav}>
                  <SegmentLabel
                    {...(item.cta?.url && { href: item.cta.url, as: 'a' })}
                  >
                    {item.label}
                  </SegmentLabel>
                  <SegmentSubItems>
                    {item.submenu.map((subitem, index) => {
                      const keyNavSub = `navmenu-${subitem.label.trim()}-${index}`;
                      return (
                        <a
                          key={keyNavSub}
                          {...(subitem.url && { href: subitem.url })}
                          {...(subitem.external && { target: '_blank' })}
                          {...(subitem.external && {
                            rel: 'noreferrer noopener',
                          })}
                        >
                          {subitem.label}
                        </a>
                      );
                    })}
                  </SegmentSubItems>
                </NavSegmentsGroup>
              );
            })}
          </NavSegments>
          <NavIcons>
            <NavSocials>
              {socialItems.map((item, index) => {
                const keyNav = `navmenu-${item.service.trim()}-${index}`;
                return (
                  <a
                    key={keyNav}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    title={item.user}
                  >
                    {item.icon}
                  </a>
                );
              })}
            </NavSocials>
            <NavApps>
              <a
                href="https://apps.apple.com/us/developer/loadsmart-inc/id983298955"
                target="_blank"
                rel="noreferrer noopener"
              >
                <AppStoreIcon width="179.04" height="60" />
              </a>
              <a
                href="https://play.google.com/store/apps/developer?id=Loadsmart,+Inc.&hl=en_US"
                target="_blank"
                rel="noreferrer noopener"
              >
                <GooglePlayIcon width="202.85" height="60" />
              </a>
            </NavApps>
          </NavIcons>
        </NavWrapper>
        <Spacer />
        <LegalWrapper>
          {legalItems.map((item, index) => {
            const keyNav = `navmenu-${item.label.trim()}-${index}`;
            return (
              <a
                key={keyNav}
                {...(item.cta?.url && { href: item.cta.url })}
                {...(item.cta?.external && { target: '_blank' })}
                {...(item.cta?.external && { rel: 'noreferrer noopener' })}
              >
                {item.label}
              </a>
            );
          })}
        </LegalWrapper>
        <CopyRightWrapper>
          © {currentYear} Loadsmart Inc.. All rights reserved. SOC2 Type II.
        </CopyRightWrapper>
      </MainContent>
    </ContainerWrapper>
  );
}
